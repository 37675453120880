import React from 'react'
import paint from '../../assets/paint.mp4'
import './Hero.css'

const Hero = () => {
  return (
    <div id='Home' className='hero' >
        <video autoPlay muted loop id='video' >
            <source src={paint} type='video/mp4' />
        </video>
        <div className="hero-text">
            <h1>ماتركس للترميمات العامه</h1>
            {/* <h1></h1> */}
            <p>عشرون عاما من الخبره في مجال الترميات الخرسانيه</p>
        </div>
        {/* <div className="bottom-text"></div> */}
    </div>
  )
}

export default Hero