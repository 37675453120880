import React from 'react'
import './About.css'

const About = () => {
  return (
    <div  id='About' className='about' >
        <div className="container">
            <div className="row-one">
                <div ><h1> من نحن</h1>  </div>
                <div></div>
            </div>
            <div className="row-two">
                <div> <h2>   نحن شركه مختصه في الترميات الخرسانيه منذ اكثر من 20 عاما </h2>     </div>
                <div> <p> لقد نفذنا اكثر من  650 مشروع في حميع انحاء الشرق الاوسط ونتمتع بثفه عملاءنا</p></div>
            </div>
        </div>
    </div>
  )
}

export default About