import './App.css';
import About from './comps/about/About';
import { Contact } from './comps/contact/Contact';
import Hero from './comps/hero/Hero';
import Navbar from './comps/navbar/Navbar';
import  {Work}  from './comps/work/Work';

function App() {
  return (
    <div className="app">
      <Navbar/>
      <Hero/>
      <About/>
      <Work/>
      <Contact/>
    </div>
  );
}

export default App;
