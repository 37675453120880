import React from "react";
import "./Work.css";
import img1 from "../../works/01.PNG";
import img2 from "../../works/02.PNG";
import img3 from "../../works/03.PNG";
import img4 from "../../works/04.PNG";
import img5 from "../../works/05.PNG";
import img6 from "../../works/06.PNG";
import img7 from "../../works/07.PNG";
import img8 from "../../works/08.PNG";
import img9 from "../../works/09.PNG";
import img10 from "../../works/10.PNG";

export const Work = () => {
  return (
    <div id= "Work" className="work">
      <div > <h1>   تفحص عملنا وشاركنا رأيك</h1> </div>
      <div className="container">
        {/* <div>
          <img className="work-img" src={img1} alt="" />
        </div> */}
        <div>
          <img className="work-img" src={img2} alt="" />
        </div>
        <div>
          <img className="work-img" src={img3} alt="" />
        </div>
        <div>
          <img className="work-img" src={img4} alt="" />
        </div>
        <div>
          <img className="work-img" src={img5} alt="" />
        </div>
        <div>
          <img className="work-img" src={img6} alt="" />
        </div>
        <div>
          <img className="work-img" src={img7} alt="" />{" "}
        </div>
        <div>
          <img className="work-img" src={img8} alt="" />
        </div>
        <div>
          <img className="work-img" src={img9} alt="" />
        </div>
        <div>
          <img className="work-img" src={img10} alt="" />
        </div>
      </div>
    </div>
  );
};
