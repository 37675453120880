import React, { useState } from "react";
import "./Navbar.css";
import logo from "../../assets/mylogo.png";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const Navbar = () => {
  const [nav, setnav] = useState(false);
  const handleClick = () => setnav(!nav);

  return (
    <div className="navbar">
    <div className="content">
      <div>
        {" "}
        <img className="logo" src={logo} alt="" />{" "}
      </div>
      
      

        <ul className={nav ? "nav active" : "nav"}>
          <li onClick={handleClick}  className="nav-item" >  <a  href="#Home" >  الرئيسية      </a>  </li>
          <li onClick={handleClick}    className="nav-item" > <a  href="#About" >   من نحن    </a> </li>
          {/* <li onClick={handleClick}    className="nav-item" > <a  href="#" >  المهارات    </a> </li> */}
          <li   onClick={handleClick} className="nav-item" >  <a  href="#Work" >  اعمالنا    </a> </li>
          <li   onClick={handleClick}  className="nav-item" > <a  href="#Contact" >   تواصل معنا    </a> </li>
        </ul>
        <div onClick={handleClick} className="hamburger">
          {nav ? (
            <AiOutlineClose className="icon" />
          ) : (
            <AiOutlineMenu className="icon" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
