
import React from 'react'
import './Contact.css'

export const Contact = () => {
  return (
    <div  id='Contact' className='contact' >
            <div className="content1">
            <h1 > حافظ علي تراثك وذكرياتك وتواصل معنا</h1>

            </div>

       

        <div>
            <form method='POST' action="https://getform.io/f/a26e693c-0ef3-4b77-920e-4070c87612b1">
                <input type="text" placeholder='Name' name='name'  />
                <input type='email' placeholder='Email' name='email' />
                <textarea name="message" placeholder='Message' rows="10"></textarea>
                <button className='btn' > دعنا نتعاون معا</button>
            </form>
        </div>
    </div>
  )
}
